<template>
  <div style="height: 100%">
    <div class="op-container">
      <div v-if="!isEdit && !isAdd" @click="add">
        <el-button type="primary" size="small">
          <i class="el-icon-plus" style="color: #fff; margin-right: 2px"></i>
          {{ $t("review.tip1") }}
        </el-button>
      </div>
      <div v-if="isEdit" class="btn-group">
        <el-button type="info" size="small" @click="deleteDialogVisible = true"
          ><i class="iconfont icon-delete" style="margin-right: 5px"></i>
          {{ $t("btn.deleteBtn") }}</el-button
        >
        <el-button
          type="info"
          size="small"
          :loading="exitEditLoading"
          @click="exitEdit"
          ><i class="iconfont icon-exit" style="margin-right: 5px"></i>
          {{ $t("btn.exitEditBtn") }}</el-button
        >
        <el-button
          type="primary"
          size="small"
          @click="exitEditAndSave(currentItemId)"
        >
          <i class="iconfont icon-branch_edit" style="margin-right: 5px"></i>
          {{ $t("changeRequest.saveAndExitBtn") }}
        </el-button>
      </div>
      <div v-if="isAdd" class="btn-group">
        <el-button type="info" size="small" @click="exitAdd"
          ><i class="iconfont icon-exit" style="margin-right: 5px"></i>
          {{ $t("btn.exitEditBtn") }}</el-button
        >
        <el-button type="primary" size="small" @click="exitAddAndSave">
          <i class="iconfont icon-branch_edit" style="margin-right: 5px"></i>
          {{ $t("changeRequest.saveAndExitBtn") }}
        </el-button>
      </div>
    </div>

    <div class="pingshen" style="height: calc(100% - 80px); overflow-y: scroll">
      <div v-if="!isEdit && !isAdd">
        <ul
          v-for="item in pingshenRuleList"
          :key="item.reviewConfigId"
          class="pingshen-list"
        >
          <li v-if="!isEdit" class="pingshen-list__header">
            <div class="header">
              <h1>{{ item.name }}</h1>
              <el-button
                type="primary"
                size="small"
                @click="edit(item.reviewConfigId)"
              >
                <i
                  class="iconfont icon-branch_edit"
                  style="margin-right: 5px"
                ></i
                >{{ $t("btn.editBtn") }}
              </el-button>
            </div>
          </li>
          <li class="pingshen-list__item">
            <div class="label">{{ $t("review.tip2") }}</div>
            <div class="value">{{ getFileTypeString(item.fileTypeList) }}</div>
          </li>
          <li class="pingshen-list__item">
            <div class="label">{{ $t("review.tip3") }}</div>
            <ul v-if="item.reviewerList.length > 0" class="user-list">
              <li
                v-for="userId in item.reviewerList"
                v-show="getUser(userId)"
                :key="userId"
                class="user-list__item"
              >
                <personal-avatar
                  :placement="'top'"
                  :size="40"
                  :avatar="(getUser(userId) && getUser(userId).avatar) || ''"
                  :nickname="getUser(userId) && getUser(userId).nickname"
                  :colorid="getUser(userId) && getUser(userId).accountId"
                  style="margin: 0 auto"
                ></personal-avatar>
                <span>{{ getUser(userId) && getUser(userId).nickname }}</span>
              </li>
            </ul>
            <div v-else style="font-size: 14px">{{ $t("review.tip4") }}</div>
          </li>
          <li class="pingshen-list__item">
            <div class="label" style="">{{ $t("review.tip5") }}</div>
            <div class="value">
              {{ $t("review.tip6") }}
              <span class="strong">{{ item.threshold }}</span>
              {{ $t("review.tip18") }}
            </div>
          </li>
          <li class="pingshen-list__item" style="align-items: flex-start">
            <div class="label">{{ $t("review.tip7") }}</div>
            <ul class="item-list">
              <li
                v-for="(pingshenItem, index) in item.itemList"
                :key="index"
                class="item-list__item"
              >
                <div class="l" style="font-size: 14px">{{ index + 1 }}</div>
                <div class="v" style="font-size: 14px">
                  {{ pingshenItem.content }}
                </div>
              </li>
            </ul>
          </li>
          <li class="pingshen-list__item">
            <div class="label" style="">
              {{ $t("canvas.reviewRequestForm.tip9") }}
            </div>
            <div class="value">
              <el-switch
                disabled
                v-model="item.allowedBatchExec"
                active-color="#13ce66"
                @change="shange_allowedBatchExec(item)"
              >
              </el-switch>
            </div>
          </li>
        </ul>
      </div>
      <div v-if="isEdit && !isAdd">
        <ul class="pingshen-list padding-small">
          <li class="pingshen-list__item">
            <div class="label label__must">{{ $t("review.tip9") }}</div>
            <el-input
              v-model="currentItem.name"
              size="small"
              style="width: 400px"
            ></el-input>
          </li>
          <li class="pingshen-list__item">
            <div class="label label__must">{{ $t("review.tip2") }}</div>
            <el-select
              v-model="currentItem.fileTypeList"
              v-defaultSelect="currentFileTypeListInReviewing"
              multiple
              filterable
              :placeholder="$t('placeholder.dropdown')"
              style="width: 400px"
            >
              <el-option
                v-for="item in currentFileTypeList"
                :key="item.fileTypeId"
                :disabled="isDisabled(item.fileTypeId)"
                :label="item.name"
                :value="item.fileTypeId"
              >
              </el-option>
            </el-select>
          </li>
          <li class="pingshen-list__item">
            <div class="label">{{ $t("review.tip3") }}</div>
            <el-select
              v-model="currentItem.reviewerList"
              multiple
              filterable
              :placeholder="$t('placeholder.dropdown')"
              style="width: 400px"
            >
              <el-option
                v-for="user in allUserList"
                v-show="
                  !user.deleted &&
                  PUserList.some((item) => {
                    return item.accountId === user.accountId;
                  })
                "
                :key="user.accountId"
                :label="user.nickname"
                :value="user.accountId"
              >
              </el-option>
            </el-select>
          </li>
          <li class="pingshen-list__item">
            <div class="label label__must" style="">
              {{ $t("review.tip5") }}
            </div>
            <div class="value" style="display: flex; align-items: center">
              {{ $t("review.tip6") }}
              <el-input
                v-model="currentItem.threshold"
                style="width: 100px; margin: 0 10px"
                size="small"
              ></el-input>
              {{ $t("review.tip18") }}
            </div>
          </li>
          <li class="pingshen-list__item" style="align-items: flex-start">
            <div class="label">{{ $t("review.tip7") }}</div>
            <div style="width: 80%">
              <ul class="item-list">
                <li
                  v-for="(item, idx) in currentItem.itemList"
                  :key="idx"
                  class="item-list__item"
                >
                  <div class="l" style="font-size: 14px">{{ idx + 1 }}</div>
                  <el-input v-model="item.content" size="small"></el-input>
                  <svg
                    v-if="idx !== 0"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    style="
                      height: 20px;
                      width: 20px;
                      margin-left: 10px;
                      cursor: pointer;
                    "
                    color="#fa5252"
                    viewBox="0 0 512 512"
                    @click="deleteCurrentItem(idx)"
                  >
                    <title>Close</title>
                    <path
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="64"
                      d="M368 368L144 144M368 144L144 368"
                    />
                  </svg>
                </li>
              </ul>
              <div
                class="add"
                style="
                  display: flex;
                  justify-content: flex-start;
                  margin-left: -4px;
                  cursor: pointer;
                "
                @click="addPingshenItem"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="20"
                  height="20"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <defs>
                    <rect id="path_0" x="0" y="0" width="16" height="16" />
                  </defs>
                  <g opacity="1" transform="translate(0 0)  rotate(0 8 8)">
                    <mask id="bg-mask-0" fill="white">
                      <use xlink:href="#path_0" />
                    </mask>
                    <g mask="url(#bg-mask-0)">
                      <path
                        :id="$t('review.tip8') + ' 1'"
                        style="
                          stroke: #30648f;
                          stroke-width: 1.3333333333333333;
                          stroke-opacity: 1;
                          stroke-dasharray: 0 0;
                        "
                        transform="translate(1.3333333333333333 1.3333333333333333)  rotate(0 6.666666666666666 6.666666666666666)"
                        d="M13.33,6.67C13.33,2.98 10.35,0 6.67,0C2.98,0 0,2.98 0,6.67C0,10.35 2.98,13.33 6.67,13.33C10.35,13.33 13.33,10.35 13.33,6.67Z "
                      />
                      <path
                        :id="$t('review.tip8') + ' 2'"
                        style="
                          stroke: #30648f;
                          stroke-width: 1.3333333333333333;
                          stroke-opacity: 1;
                          stroke-dasharray: 0 0;
                        "
                        transform="translate(8 5.333333333333333)  rotate(0 0 2.6666666666666665)"
                        d="M0,0L0,5.33 "
                      />
                      <path
                        :id="$t('review.tip8') + ' 3'"
                        style="
                          stroke: #30648f;
                          stroke-width: 1.3333333333333333;
                          stroke-opacity: 1;
                          stroke-dasharray: 0 0;
                        "
                        transform="translate(5.333333333333333 8)  rotate(0 2.6666666666666665 0)"
                        d="M0,0L5.33,0 "
                      />
                    </g>
                  </g>
                </svg>
              </div>
            </div>
          </li>
          <li class="pingshen-list__item">
            <div class="label" style="">
              {{ $t("canvas.reviewRequestForm.tip9") }}
            </div>
            <div class="value">
              <el-switch
                v-model="currentItem.allowedBatchExec"
                active-color="#13ce66"
                @change="shange_allowedBatchExec(currentItem)"
              >
              </el-switch>
            </div>
          </li>
        </ul>
      </div>
      <div v-if="isAdd">
        <ul class="pingshen-list padding-small">
          <li class="pingshen-list__item">
            <div class="label label__must">{{ $t("review.tip9") }}</div>
            <el-input
              v-model="addItem.name"
              style="width: 400px"
              :placeholder="$t('review.tip10')"
            ></el-input>
          </li>
          <li class="pingshen-list__item">
            <div class="label label__must">{{ $t("review.tip2") }}</div>
            <el-select
              v-model="addItem.fileType"
              multiple
              filterable
              :placeholder="$t('placeholder.dropdown')"
              style="width: 400px"
            >
              <el-option
                v-for="item in fileTypeList.filter(
                  (file) => !alreadyAddFileTypeList.includes(file.fileTypeId)
                )"
                :key="item.fileTypeId"
                :label="item.name"
                :value="item.fileTypeId"
              >
              </el-option>
            </el-select>
          </li>
          <li class="pingshen-list__item">
            <div class="label">{{ $t("review.tip3") }}</div>
            <el-select
              v-model="addItem.userList"
              multiple
              filterable
              :placeholder="$t('placeholder.dropdown')"
              style="width: 400px"
            >
              <el-option
                v-for="user in allUserList"
                v-show="
                  !user.deleted &&
                  PUserList.some((item) => {
                    return item.accountId === user.accountId;
                  })
                "
                :key="user.accountId"
                :label="user.nickname"
                :value="user.accountId"
              >
              </el-option>
            </el-select>
          </li>
          <li class="pingshen-list__item">
            <div class="label label__must" style="">
              {{ $t("review.tip5") }}
            </div>
            <div class="value" style="display: flex; align-items: center">
              {{ $t("review.tip6") }}
              <el-input
                v-model="addItem.passNumber"
                style="width: 100px; margin: 0 10px"
                size="small"
              ></el-input>
              {{ $t("review.tip18") }}
            </div>
          </li>
          <li class="pingshen-list__item" style="align-items: flex-start">
            <div class="label">{{ $t("review.tip7") }}</div>
            <div style="width: 80%">
              <ul class="item-list">
                <li
                  v-for="(_, index) in addItem.items"
                  :key="index"
                  class="item-list__item"
                >
                  <div class="l">{{ index + 1 }}</div>
                  <el-input
                    v-model="addItem.items[index]"
                    size="small"
                  ></el-input>
                  <svg
                    v-if="index !== 0"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    style="
                      height: 20px;
                      width: 20px;
                      margin-left: 10px;
                      cursor: pointer;
                    "
                    color="#fa5252"
                    viewBox="0 0 512 512"
                    @click="deleteAddItem(index)"
                  >
                    <title>Close</title>
                    <path
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="64"
                      d="M368 368L144 144M368 144L144 368"
                    />
                  </svg>
                </li>
              </ul>
              <div
                class="add"
                style="
                  display: flex;
                  justify-content: flex-start;
                  margin: 20px 0 0 -5px;
                  cursor: pointer;
                "
                @click="addPingshenItemInAdd"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="20"
                  height="20"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <defs>
                    <rect id="path_0" x="0" y="0" width="16" height="16" />
                  </defs>
                  <g opacity="1" transform="translate(0 0)  rotate(0 8 8)">
                    <mask id="bg-mask-0" fill="white">
                      <use xlink:href="#path_0" />
                    </mask>
                    <g mask="url(#bg-mask-0)">
                      <path
                        :id="$t('review.tip8') + ' 1'"
                        style="
                          stroke: #30648f;
                          stroke-width: 1.3333333333333333;
                          stroke-opacity: 1;
                          stroke-dasharray: 0 0;
                        "
                        transform="translate(1.3333333333333333 1.3333333333333333)  rotate(0 6.666666666666666 6.666666666666666)"
                        d="M13.33,6.67C13.33,2.98 10.35,0 6.67,0C2.98,0 0,2.98 0,6.67C0,10.35 2.98,13.33 6.67,13.33C10.35,13.33 13.33,10.35 13.33,6.67Z "
                      />
                      <path
                        :id="$t('review.tip8') + ' 2'"
                        style="
                          stroke: #30648f;
                          stroke-width: 1.3333333333333333;
                          stroke-opacity: 1;
                          stroke-dasharray: 0 0;
                        "
                        transform="translate(8 5.333333333333333)  rotate(0 0 2.6666666666666665)"
                        d="M0,0L0,5.33 "
                      />
                      <path
                        :id="$t('review.tip8') + ' 3'"
                        style="
                          stroke: #30648f;
                          stroke-width: 1.3333333333333333;
                          stroke-opacity: 1;
                          stroke-dasharray: 0 0;
                        "
                        transform="translate(5.333333333333333 8)  rotate(0 2.6666666666666665 0)"
                        d="M0,0L5.33,0 "
                      />
                    </g>
                  </g>
                </svg>
              </div>
            </div>
          </li>
          <li class="pingshen-list__item">
            <div class="label" style="">
              {{ $t("canvas.reviewRequestForm.tip9") }}
            </div>
            <div class="value">
              <!-- {{addItem}} -->
              <el-switch
                v-model="addItem.allowedBatchExec"
                active-color="#13ce66"
                @change="shange_allowedBatchExec(addItem)"
              >
              </el-switch>
            </div>
          </li>
        </ul>
      </div>
      <el-dialog
        class="test"
        :close-on-click-modal="false"
        :show-close="true"
        :title="$t('review.tip11')"
        :visible.sync="deleteDialogVisible"
        width="25%"
      >
        <p style="text-align: left">{{ $t("review.tip12") }}</p>
        <span slot="footer" class="dialog-footer">
          <el-button type="info" @click="deleteDialogVisible = false">{{
            $t("review.tip13")
          }}</el-button>
          <el-button type="primary" @click="deleteSelf(currentItemId)">{{
            $t("review.tip14")
          }}</el-button>
        </span>
      </el-dialog>

      <el-dialog
        class="test"
        :close-on-click-modal="false"
        :show-close="false"
        :title="$t('review.tip11')"
        :visible.sync="warn_dig_show"
        width="25%"
      >
        <p style="text-align: left">
          {{ $t("canvas.reviewRequestForm.tip10") }}
        </p>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="warn_dig_show = false">{{
            $t("review.tip14")
          }}</el-button>
          <el-button type="info" @click="qvxiaopiliang()">{{
            $t("review.tip13")
          }}</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  addReviewConfig,
  deleteReviewConfig,
  getReviewConfig,
  updateReviewConfig,
  getFileIdInReviewing,
} from "@/network/collaboration/index.js";
import { get_filetype_List } from "@/network/fileType/index.js";
import { mapGetters } from "vuex";
import PersonalAvatar from "@/components/personal";
import { get_allProjectMember } from "@/network/node/index.js";
import { timingSafeEqual } from "crypto";
import M from "minimatch";
import { config } from "process";

export default {
  name: "Pingshen",
  components: {
    PersonalAvatar,
  },
  data() {
    return {
      warn_dig_show: false,
      deleteDialogVisible: false,
      alreadyAddFileTypeList: [],
      fileTypeList: [],
      isEdit: false,
      pingshenValue: false,
      isAdd: false,
      pingshenRuleList: [],
      currentItem: {},
      currentItemId: 0,
      addItem: {
        items: [""],
        passNumber: 100,
        allowedBatchExec: false,
      },
      currentFileTypeList: [],
      currentFileTypeListInReviewing: [],
      exitEditLoading: false,
    };
  },
  computed: {
    ...mapGetters(["user_list", "project_user_list"]),
    allUserList() {
      return Object.values(this.user_list);
    },
    PUserList() {
      return Object.values(this.project_user_list);
    },
  },
  mounted() {
    getReviewConfig(this.get_pid()).then((configs) => {
      get_filetype_List(this.get_pid()).then((res) => {
        this.fileTypeList = res;
        this.alreadyAddFileTypeList = [];
        configs.forEach((item) => {
          this.pingshenRuleList.push(item);
          item.fileTypeList.forEach((file) => {
            this.alreadyAddFileTypeList.push(file.fileTypeId);
          });
        });
      });
    });
  },
  methods: {
    qvxiaopiliang() {
      this.warn_dig_show = false;
      this.currentItem.allowedBatchExec = false;
      this.addItem.allowedBatchExec = false;
    },
    shange_allowedBatchExec(item) {
      if (item.allowedBatchExec) {
        this.warn_dig_show = true;
      }
    },
    isDisabled(id) {
      let flag = false;
      this.currentFileTypeListInReviewing.forEach((fileType) => {
        if (fileType.fileTypeId === id) {
          flag = true;
        }
      });
      return flag;
    },
    addPingshenItemInAdd() {
      this.addItem.items.push("");
    },
    exitAdd() {
      this.isAdd = false;
    },
    isNotEmpty(name, fileType, number) {
      let errorMessage = "";
      if (!name) {
        errorMessage = this.$t("review.tip15");
      } else if (!fileType || (fileType && fileType.length < 1)) {
        errorMessage = this.$t("review.tip16");
      } else if (
        !number ||
        !Number.isFinite(+number) ||
        number < 0 ||
        number > 100
      ) {
        errorMessage = this.$t("review.tip17");
      }
      if (errorMessage) {
        this.$message.error(errorMessage);
      }
      return errorMessage !== "";
    },
    exitAddAndSave() {
      if (
        this.isNotEmpty(
          this.addItem.name,
          this.addItem.fileType,
          this.addItem.passNumber
        )
      ) {
        return;
      }
      // if (this.addItem.items.some((item) => item === "")) {
      //   this.$message.error(this.$t("review.tip20"));
      //   return;
      // }
      addReviewConfig(this.get_pid(), {
        fileTypeList: this.addItem.fileType,
        name: this.addItem.name,
        itemList: this.addItem.items.map((item) => {
          return { content: item };
        }),
        reviewerList: this.addItem.userList,
        threshold: +this.addItem.passNumber,
        allowedBatchExec: this.addItem.allowedBatchExec,
      })
        .then((res) => {
          getReviewConfig(this.get_pid()).then((res) => {
            this.pingshenRuleList = [];
            this.alreadyAddFileTypeList = [];
            res.forEach((item) => {
              this.pingshenRuleList.push(item);

              item.fileTypeList.forEach((file) => {
                this.alreadyAddFileTypeList.push(file.fileTypeId);
              });
            });
          });
          this.isAdd = false;
          this.addItem = {
            items: [],
            allowedBatchExec: false,
          };
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
    add() {
      this.isAdd = true;
      this.addItem.allowedBatchExec = false;
    },
    setCurrentItem(src) {
      const tmp = [];
      src.fileTypeList.forEach((file) => {
        tmp.push(file.fileTypeId);
      });

      this.currentItem = {
        reviewConfigId: src.reviewConfigId,
        fileTypeList: tmp,
        reviewerList: src.reviewerList,
        itemList: src.itemList,
        threshold: src.threshold,
        name: src.name,
        allowedBatchExec: src.allowedBatchExec,
      };
    },
    edit(id) {
      this.isEdit = true;
      this.currentItemId = id;
      const currentItemSrc = this.pingshenRuleList.filter(
        (rule) => rule.reviewConfigId === id
      )[0];
      //剩下未被选择的fileType
      this.currentFileTypeList = this.fileTypeList.filter(
        (file) => !this.alreadyAddFileTypeList.includes(file.fileTypeId)
      );
      this.currentFileTypeList = [
        ...currentItemSrc.fileTypeList,
        ...this.currentFileTypeList,
      ];

      //去重
      const map = new Map();
      this.currentFileTypeList.forEach((file) => {
        if (!map.has(file.fileTypeId)) {
          map.set(file.fileTypeId, file);
        }
      });
      this.currentFileTypeList = [...map.values()];

      //在评审中的文件
      getFileIdInReviewing(this.get_pid()).then((res) => {
        this.currentFileTypeListInReviewing = [];
        res.forEach((id) => {
          const item = this.fileTypeList.find((file) => id === file.fileTypeId);
          this.currentFileTypeListInReviewing.push(item);
        });
      });

      this.setCurrentItem(currentItemSrc);
    },
    exitEdit() {
      this.exitEditLoading = true;
      getReviewConfig(this.get_pid()).then((res) => {
        this.pingshenRuleList = [];
        this.alreadyAddFileTypeList = [];
        res.forEach((item) => {
          this.pingshenRuleList.push(item);
          item.fileTypeList.forEach((file) => {
            this.alreadyAddFileTypeList.push(file.fileTypeId);
          });
        });
        this.isEdit = false;
        this.exitEditLoading = false;
      });
    },
    exitEditAndSave(id) {
      if (
        this.isNotEmpty(
          this.currentItem.name,
          this.currentItem.fileTypeList,
          this.currentItem.threshold
        )
      ) {
        return;
      }
      // if (this.currentItem.itemList.some((item) => item.content === "")) {
      //   this.$message.error(this.$t("review.tip20"));
      //   return;
      // }
      this.isEdit = false;
      updateReviewConfig(this.get_pid(), id, this.currentItem).then((res) => {
        getReviewConfig(this.get_pid()).then((res) => {
          this.pingshenRuleList = [];
          this.alreadyAddFileTypeList = [];
          res.forEach((item) => {
            this.pingshenRuleList.push(item);
            item.fileTypeList.forEach((file) => {
              this.alreadyAddFileTypeList.push(file.fileTypeId);
            });
          });
        });
      });
    },
    getFileTypeString(fileType) {
      let str = "";
      fileType.forEach((file) => {
        this.fileTypeList.forEach((fileItem) => {
          if (file.fileTypeId === fileItem.fileTypeId) {
            str += fileItem.name + ", ";
          }
        });
      });
      return str.slice(0, str.length - 2);
    },
    getUser(id) {
      return this.matchUserInfo(id);
    },
    deleteSelf(id) {
      deleteReviewConfig(this.get_pid(), id).then((res) => {
        getReviewConfig(this.get_pid()).then((res) => {
          this.pingshenRuleList = [];
          this.alreadyAddFileTypeList = [];
          res.forEach((item) => {
            this.pingshenRuleList.push(item);
            item.fileTypeList.forEach((file) => {
              this.alreadyAddFileTypeList.push(file.fileTypeId);
            });
          });
        });
      });
      this.deleteDialogVisible = false;
      this.isEdit = false;
    },
    addPingshenItem() {
      this.currentItem.itemList.push({ content: "" });
    },
    deleteCurrentItem(index) {
      this.currentItem.itemList.splice(index, 1);
    },
    deleteAddItem(index) {
      this.addItem.items.splice(index, 1);
    },
  },
};
</script>

<style scoped lang="scss">
.pingshen-list__item {
  .label {
    width: 130px;
    text-align: left;
  }
}
.btn-box-common {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(51, 102, 144, 1);
  border-radius: 4px;
  color: rgba(51, 102, 144, 1);
  cursor: pointer;
}
.btn-box-group {
  padding: 4px 8px;
}
.btn {
  background-color: #fff;
  border: none;
  color: rgba(51, 102, 144, 1);
  cursor: pointer;
  &--transparent {
    background-color: transparent;
  }
  &--color-white {
    color: #fff;
  }
}
.pingshen-list {
  &:not(:last-child) {
    margin-bottom: 60px;
  }
  list-style: none;
  padding: 0;
  border-radius: 6px;
  overflow: hidden;

  &__item {
    background-color: #fff;
    display: flex;
    padding: 30px 50px;
    align-items: center;
    color: rgba(128, 128, 128, 1);
    &:not(:last-child) {
      border-bottom: 1px solid rgba(229, 229, 229, 1);
    }

    .item-list {
      padding: 0;
      margin: 0;
      list-style: none;
      flex-grow: 1;

      &__item {
        display: flex;
        padding: 20px 20px 20px 0 !important;
        &:first-of-type {
          padding: 0 20px 20px 0 !important;
        }
        &:not(:last-child) {
          border-bottom: 1px solid rgba(229, 229, 229, 1);
        }
        align-items: center;
        .l {
          font-size: 18px;
          margin-right: 36px;
        }
        .v {
          font-size: 18px;
        }
      }
    }

    .user-list {
      list-style: none;
      display: flex;
      gap: 32px;
      padding: 0;
      margin: 0;
      overflow-x: auto;
      &::-webkit-scrollbar {
        width: 6px;
        height: 8px;
        background-color: #ebeef5;
      }
      &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #ccc;
        border-radius: 4px;
      }
      &::-webkit-scrollbar-track {
        border-radius: 3px;
        background: rgba(255, 255, 255, 1);
      }
      &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 6px;
        img {
          height: 56px;
          width: 56px;
          border-radius: 50%;
        }
      }
    }

    .label {
      font-size: 14px;
      font-weight: 600;
      margin-right: 60px;
      // position: relative;
    }
    .label {
      &__must::after {
        content: "*";
        color: red;
      }
    }
    .value {
      font-size: 14px;

      .strong {
        color: rgba(51, 102, 144, 1);
        font-weight: 600;
        font-size: 18px;
      }
    }
  }

  &__header {
    background: rgba(251, 251, 251, 1);
    border-bottom: 1px solid rgba(229, 229, 229, 1);
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 30px;
      h3 {
        margin: 0;
      }
      .btn-box-edit {
        padding: 4px 8px;
        border-radius: 4px;
        color: #fff !important;
        background-color: rgba(51, 102, 144, 1);

        svg {
          margin-right: 4px;
          color: #fff !important;
        }
      }
    }
  }
}
.op-container {
  display: flex;
  justify-content: right;
  align-items: center;
  margin-bottom: 30px;
}
.btn-box-add {
  width: 8%;
  padding: 2px 3px;
  border-radius: 4px;
  svg {
    width: 24px;
    height: 24px;
    margin-right: 5px;
  }
  button {
    background-color: transparent;
  }
}
.btn-group {
  display: flex;
  gap: 12px;
}
.padding-small {
  li {
    padding: 20px !important;
  }
}
</style>
<style lang="scss">
.test .el-dialog__body {
  padding: 0 30px;
}
.none {
  display: none !important;
}
</style>
